<template>
  <!-- Render the nonAuthViews -->
  <v-app v-if="$store.state.nonAuthViews.includes($route.path)">
    <router-view></router-view>
  </v-app>
  <v-app v-else>
    <v-navigation-drawer
      app
      class="overflow-y-scroll"
      clipped
      flat
      v-model="drawer.toggle"
      width="310"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title> Lorem Ipsum</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list rounded>
          <v-list-group
            :key="index"
            color="primary"
            v-for="(_module, index) in $store.state.site.navigation_routes"
          >
            <template v-slot:activator>
              <v-list-item-action>
                <v-icon color="text--primary">{{ _module.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="_module.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-group>
        </v-list>
      </template>
    </v-navigation-drawer>
    <!-- App Bar -->
    <v-app-bar
      app
      class="elevation-0"
      color="navigation"
      clipped-left
      outlined
      scroll-off-screen
      text
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mobile"
        @click="drawer.toggle = !drawer.toggle"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="px-0">
        <v-container fluid>
          <v-col class="px-0 mt-1">
            <img
              v-if="!dark_mode"
              :src="$store.state.site.lightLogo"
              width="120px"
            />
            <img v-else :src="$store.state.site.darkLogo" width="120px" />
          </v-col>
        </v-container>
      </v-toolbar-title>
      <v-btn
        v-for="(link, i) in $store.state.site.navigation_routes"
        :key="i"
        :to="link.path"
        rounded
        light
        color="primary"
        class="ml-2 google-font hidden-sm-and-down this.$vuetify.theme.dark?'whiteText':'blackText'"
        style="text-transform: capitalize"
        text
      >
        <v-icon medium class="mr-2" color="button">{{ link.icon }}</v-icon>
        {{ link.name }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="darkMode()" class="mr-2"
        ><v-icon>{{
          dark_mode ? "mdi-lightbulb-on" : "mdi-lightbulb-outline"
        }}</v-icon></v-btn
      >
      <v-btn icon @click="logout()" class="mr-2"
        ><v-icon> mdi-logout </v-icon></v-btn
      >
    </v-app-bar>
    <!-- Render Routes -->
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    dark_mode: false,
    drawer: {
      toggle: false,
    },
    loading: false,
    timeout: 25000,
    isLoading: false,
    user_profile: false,
    // PWA Utis
    registration: null,
    updateExists: false,
  }),
  beforeCreate() {
    localStorage.getItem("darkMode") == "true"
      ? (this.$vuetify.theme.dark = true)
      : (this.$vuetify.theme.dark = false);
  },
  mounted() {
    //this.$store.dispatch("connect");
    if (localStorage.getItem("accessToken")) {
      this.access_token = localStorage.getItem("accessToken");
      this.verify();
    }
  },
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    darkMode() {
      let that = this;
      let metaThemeColor = document.querySelector("meta[name=theme-color]");
      that.$vuetify.theme.dark = !that.$vuetify.theme.dark;
      if (localStorage)
        localStorage.setItem("darkMode", that.$vuetify.theme.dark);
      if (that.$vuetify.theme.dark) {
        metaThemeColor.setAttribute("content", "#212121");
        that.dark_mode = true;
      } else {
        metaThemeColor.setAttribute("content", "#FFF");
        that.dark_mode = false;
      }
    },
    verify() {
      let that = this;
      that.$store
        .dispatch("Verify_Token")
        .then(() => {})
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            that.$router.replace("/");
          }
        });
    },
    logout() {
      this.$store.dispatch("destroyToken").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>

<template>
  <v-main>
    <v-container class="pa-0 ma-0" fill-height fluid grid-list-md>
      <v-layout align-stretch class="ma-0" row wrap>
        <v-col class="elevation-0" cols="12" xl="4" lg="4" md="4" sm="12">
          <v-container fill-height fluid grid-list-md>
            <v-layout align-center class row wrap align="center" justify-center>
              <v-col>
                <v-card class="mx-auto elevation-0" width="400">
                  <h1
                    class="text-h4 mb-5"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 700;
                      color: #113927;
                    "
                  >
                    Welcome Back!
                  </h1>
                  <h1
                    class="text-h6 mb-5"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 400;
                    "
                  >
                    Please enter your creditionals to access WhatsApp Business
                    Platform.
                  </h1>
                  <v-form class>
                    <v-text-field
                      label="Email ID"
                      required
                      v-model="username"
                      outlined
                      style="border-radius: 10px"
                      :rules="emailRules"
                      class="mt-5"
                    ></v-text-field>
                    <v-text-field
                      @click:append="show_pass = !show_pass"
                      :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show_pass ? 'text' : 'password'"
                      label="Password"
                      required
                      style="border-radius: 10px"
                      outlined
                      :rules="mandatoryRules"
                      v-model="password"
                      class=""
                    ></v-text-field>

                    <v-layout row wrap class="mt-n4">
                      <v-row align="end" justify="end">
                        <v-col cols="12" class="mt-8">
                          <v-btn
                            class="elevation-0"
                            @click.stop="login()"
                            block
                            large
                            rounded
                            :loading="loading"
                            dark
                            color="primary"
                          >
                            Log In
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-form>
                </v-card>
                <v-footer absolute color="transparent">
                  <v-spacer> </v-spacer
                  ><v-btn icon @click="info_flag = true"
                    ><v-icon color="white"
                      >mdi-information-outline</v-icon
                    ></v-btn
                  >
                </v-footer>
              </v-col>
            </v-layout>
          </v-container>
        </v-col>
        <v-col
          style="background-color: #113927"
          cols="12"
          xl="8"
          lg="8"
          md="8"
          sm="12"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-container class fill-height fluid grid-list-md>
            <v-layout align-center row wrap justify-center>
              <v-img
                lazy-src="https://firebasestorage.googleapis.com/v0/b/whatsapp-business-53119.appspot.com/o/login_banner-min.png?alt=media&token=4ebd7a99-5920-4d10-b583-5bb8ddf5a26b"
                src="https://firebasestorage.googleapis.com/v0/b/whatsapp-business-53119.appspot.com/o/login_banner.png?alt=media&token=4e6ae1d2-95f5-47e2-9f55-484767b9a9c7"
                aspect-ratio="1.5"
                width="50%"
              ></v-img>
              <v-col cols="12" class="ml-12 mt-n8">
                <h1
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;

                    color: #44cd66;
                  "
                >
                  Transform Your Business {{ val }}
                </h1>
                <h4
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    color: #fff;
                  "
                >
                  Reach your customers anywhere with conversations<br />
                  and create memorable experiences all through WhatsApp Business
                  Platform.
                </h4>
              </v-col>
            </v-layout>
          </v-container>
        </v-col>

        <v-snackbar v-model="snackbar" :color="snackbar_color" timeout="5000">
          {{ snackbar_message }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Login_View",
  components: {},
  data: () => ({
    username: "",
    password: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],

    mandatoryRules: [(v) => !!v || "This field is required"],

    //utils
    snackbar: false,
    snackbar_message: "",
    snackbar_color: "",
    loading: false,
    show_pass: false,
    val: "",
  }),

  mounted() {
    if (localStorage.getItem("accessToken")) {
      this.access_token = localStorage.getItem("accessToken");
      this.verify();
    }
  },
  methods: {
    login() {
      let that = this;
      that.loading = true;
      that.$store
        .dispatch("login", {
          username: that.username.toLowerCase(),
          password: that.password,
        })
        .then(function () {
          that.$router.replace("/whatsapp");
          that.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            that.snackbar = true;
            that.snackbar_message = "Invalid Credentials";
            that.snackbar_color = "error";
          } else if (error.response.status === 404) {
            that.snackbar = true;
            that.snackbar_message = "User Not Found";
            that.snackbar_color = "error";
          } else {
            that.snackbar = true;
            that.snackbar_message = "Something Went Wrong";
            that.snackbar_color = "error";
          }
          that.loading = false;
        });
    },

    verify() {
      console.log("Check Token Validity");
      let that = this;
      that.$store
        .dispatch("Verify_Token")
        .then((res) => {
          if (res.status === 200) {
            that.$router.replace("/whatsapp");
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 401) {
            localStorage.clear();
            that.snack = true;
            that.$router.replace("/");
          }
        });
    },
  },
};
</script>

<style scoped></style>

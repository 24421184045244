import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/auth/Login";
import WhatsAppView from "../views/WhatsAppView";
import Analytics from "../views/Analytics";
import TemplateView from "../views/TemplateView";
import Report from "../views/Report";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Dashboard",
    component: HomeView,
  },
  {
    path: "/whatsapp",
    name: "WhatsApp",
    component: WhatsAppView,
  },
  {
    path: "/whatsapp/template/:id",
    name: "Template Details",
    component: TemplateView,
  },
  {
    path: "/reporting",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/reporting/:id/:template",
    name: "Analytics",
    component: Report,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

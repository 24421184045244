<template>
  <v-app>
    <v-main class="background">
      <v-container fluid class="mt-5">
        <v-card class="elevation-0 rounded-t-xl rounded-b-xl">
          <v-container fluid>
            <v-row class="fill-height">
              <v-col cols="8">
                <v-card
                  class="elevation-0"
                  outlined
                  style="border-radius: 20px"
                >
                  <v-sheet height="64" class="ma-3">
                    <v-toolbar flat>
                      <v-btn
                        rounded
                        outlined
                        class="mr-4"
                        color="primary"
                        @click="setToday"
                      >
                        Today
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> mdi-chevron-left </v-icon>
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> mdi-chevron-right </v-icon>
                      </v-btn>
                      <v-toolbar-title
                        v-if="$refs.calendar"
                        class="ml-3"
                        style="font-weight: bolder"
                      >
                        {{ $refs.calendar.title }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </v-sheet>
                  <v-sheet height="600" class="ma-3">
                    <v-calendar
                      ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="events"
                      type="month"
                      @click:event="showEvent"
                    ></v-calendar>
                    <v-menu
                      v-model="selectedOpen"
                      :close-on-content-click="false"
                      :activator="selectedElement"
                      offset-x
                    >
                      <v-card color="grey lighten-4" max-width="450px" flat>
                        <v-toolbar :color="selectedEvent.color" dark>
                          <v-toolbar-title
                            v-html="selectedEvent.template"
                          ></v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                          Blast ID: {{ selectedEvent.cohortId }} <br />
                          Template: {{ selectedEvent.template }} <br />
                          Details: {{ selectedEvent.details }}
                        </v-card-text>
                        <v-card-text> </v-card-text>
                        <v-card-actions>
                          <v-btn
                            text
                            color="secondary"
                            @click="
                              $router.push(
                                '/reporting/' +
                                  selectedEvent.cohortId +
                                  '/' +
                                  selectedEvent.template
                              )
                            "
                          >
                            View Analytics
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import analyticsService from "../service/analyticsService";
export default {
  name: "Home_View",
  data: () => ({
    value: "",
    events: [],
    focus: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  components: {},
  mounted() {
    this.getEvents();
  },
  computed: {},
  methods: {
    getEvents() {
      let that = this;
      analyticsService.getCohorts().then((res) => {
        that.events = res.data;
      });
    },
    onClickEvent(event) {
      console.log(event.event);
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
  },
};
</script>

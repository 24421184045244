import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

axios.defaults.headers.common["x-access-token"] =
  localStorage.getItem("accessToken");

export default new Vuex.Store({
  state: {
    nonAuthViews: ["/"],
    site: {
      name: "WhatsApp Business Console",
      url: "https://codescapes.atharvaunde.dev",
      author: "Atharva Unde",
      authorEmail: "atharva@atharvaunde.dev",
      supportEmail: "atharva@atharvaunde.dev",
      darkLogo:
        "https://mernlmsassets.s3.ap-south-1.amazonaws.com/img/Careerlabs.png",
      lightLogo:
        "https://thecareerlabs.com/wp-content/uploads/2021/06/clabslogo-1024x297.png",
      navigation_routes: [
        {
          name: "Dashboard",
          path: "/home",
          icon: "mdi-home",
        },
        {
          name: "WhatsApp Business",
          path: "/whatsapp",
          icon: "mdi-whatsapp",
        },
        {
          name: "Analytics and Reporting",
          path: "/reporting",
          icon: "mdi-finance",
        },
      ],
    },
    templateListHeaders: [
      {
        text: "Template ID",
        value: "templateId",
      },
      {
        text: "Template Name",
        value: "name",
      },
      {
        text: "Last Updated",
        value: "meta.last_updated_time",
        align: "center",
      },
      {
        text: "Status",
        value: "meta.status",
        align: "center",
      },
      {
        text: "Template Category",
        value: "meta.category",
      },
      {
        text: "Template Language",
        value: "meta.language",
        align: "start",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    auth: {
      isLoggedIn: false,
      accessToken: null || localStorage.getItem("accessToken"),
    },

    user: {
      username: null || localStorage.getItem("username"),
      name: null || localStorage.getItem("name"),
      com_unit_id: null || localStorage.getItem("com_unit_id"),
    },
  },
  getters: {
    auth_status(state) {
      return state.auth.accessToken != null;
    },
  },
  mutations: {
    setAuthContext(state, payload) {
      state.auth.isLoggedIn = true;
      state.auth.accessToken = payload.token;
      state.user.username = payload.user.username;
      state.user.name = payload.user.firstname + " " + payload.user.lastname;
      state.user.com_unit_id = payload.user.com_unit_id;
      localStorage.setItem("auth_status", true);
      localStorage.setItem("accessToken", payload.token);
      localStorage.setItem("username", payload.user.username);
      localStorage.setItem("avatar", payload.user.avatar);
      localStorage.setItem(
        "name",
        payload.user.firstname + " " + payload.user.lastname
      );
    },

    setLogout(state) {
      localStorage.clear();
      state.auth.isLoggedIn = false;
      state.auth.accessToken = null;
      state.user.username = null;
      state.user.name = null;
      state.user.com_unit_id = null;
    },
  },
  actions: {
    async login(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_Host + "/api/auth/login", payload)
          .then((res) => {
            resolve(res);
            context.commit("setAuthContext", res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async Verify_Token() {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_Host + "/api/auth/verify", {
            PERMISSION_ROLE: 9,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async destroyToken(context) {
      if (context.getters.auth_status) {
        return new Promise((resolve) => {
          localStorage.clear();
          context.commit("setLogout");
          resolve();
        });
      }
    },
  },
  modules: {},
});

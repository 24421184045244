<template>
  <v-app>
    <v-main class="background">
      <v-container fluid class="mt-5">
        <v-card class="elevation-0" style="border-radius: 20px">
          <v-container fluid>
            <v-card class="elevation-0" outlined style="border-radius: 10px">
              <v-card-title>
                <v-text-field
                  outlined
                  style="border-radius: 20px"
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Search for Templates"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                calculate-widths:true
                :search="search"
                :headers="$store.state.templateListHeaders"
                :items="templateList"
                :items-per-page="10"
                item-key="templateID"
                no-data-text="No Templates Found"
                no-results-text="No Templates Found for the given search term"
                class="elevation-0"
                :loading="loadme"
              >
                <template v-slot:[`item.meta.status`]="{ item }">
                  <v-chip
                    v-if="item.meta.status === 'APPROVED'"
                    class="ma-2"
                    color="green"
                    dark
                    small
                  >
                    Approved
                  </v-chip>
                  <v-chip v-else class="ma-2" color="orange" dark small>
                    {{ item.meta.status }}
                  </v-chip>
                </template>
                <template v-slot:[`item.actions`]="props">
                  <v-menu offset-y open-on-click>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        :disabled="props.item.meta.status != 'APPROVED'"
                      >
                        <v-icon color="primary">
                          mdi-chart-areaspline-variant</v-icon
                        >
                      </v-btn>
                      <v-btn
                        v-on="on"
                        icon
                        :disabled="props.item.meta.status != 'APPROVED'"
                        @click="
                          $router.push(
                            '/whatsapp/template/' + props.item.templateId
                          )
                        "
                      >
                        <v-icon color="primary"
                          >mdi-arrow-right-circle-outline</v-icon
                        ></v-btn
                      >
                    </template>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import templateService from "../service/templateService";
import data from "../service/data";

export default {
  name: "List_View",
  components: {},
  data: () => ({
    templateList: [],
    search: "",
    loadme: false,
  }),
  mounted() {
    this.fetchTemplates();
  },
  computed: {},
  methods: {
    fetchTemplates() {
      let that = this;
      that.loadme = true;
      templateService
        .GetTemplates()
        .then((response) => {
          that.templateList = response.data;
          for (let i = 0; i < that.templateList.length; i++) {
            data.locals.forEach(function (element) {
              if (that.templateList[i].meta.language === element.code) {
                that.templateList[i].meta.language = element.name;
              }
            });
            if (that.templateList[i].meta.category.includes("_")) {
              that.templateList[i].meta.category = that.templateList[
                i
              ].meta.category.replace("_", " ");
            }
          }
          that.loadme = false;
        })
        .catch((error) => {
          console.log(error);
          that.loadme = false;
        });
    },
  },
};
</script>

<template>
  <v-app>
    <v-main class="background">
      <v-container fluid class="mt-5">
        <v-row justify="center">
          <v-col cols="12" xl="2" lg="2" xs="12">
            <v-card class="elevation-0" style="border-radius: 10px">
              <v-card-title
                ><v-icon medium class="mr-2" color="#BDBDBD"
                  >mdi-message</v-icon
                >
                Sent: {{ getCount(report).sent }}</v-card-title
              >
              <v-card-subtitle
                >Total Messages Sent Succesfully
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" xl="2" lg="2" xs="12">
            <v-card class="elevation-0" style="border-radius: 10px">
              <v-card-title
                ><v-icon medium class="mr-2" color="#FFEE58"
                  >mdi-message</v-icon
                >
                Delivered: {{ getCount(report).delivered }}</v-card-title
              >
              <v-card-subtitle>Delivered Messages out of Sent</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" xl="2" lg="2" xs="12">
            <v-card class="elevation-0" style="border-radius: 10px">
              <v-card-title
                ><v-icon medium class="mr-2" color="success"
                  >mdi-message</v-icon
                >
                Read: {{ getCount(report).read }}</v-card-title
              >
              <v-card-subtitle>Read Messages out of delivered </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" xl="2" lg="2" xs="12">
            <v-card class="elevation-0" style="border-radius: 10px">
              <v-card-title
                ><v-icon medium class="mr-2" color="error">mdi-message</v-icon>
                Failed: {{ getCount(report).failed }}</v-card-title
              >
              <v-card-subtitle>Messages that failed to sent</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-card class="elevation-0 rounded-t-xl rounded-b-xl mt-5">
          <v-data-table
            :headers="headers"
            :items="report"
            :items-per-page="10"
            class="elevation-0"
          >
            <template v-slot:[`item.hook`]="{ item }">
              <v-chip
                v-for="(data, index) in item.hook"
                :key="index"
                class="ma-2"
                :color="
                  data.status === 'sent'
                    ? '#BDBDBD'
                    : data.status === 'delivered'
                    ? '#FFEE58'
                    : data.status === 'failed'
                    ? 'red'
                    : 'green'
                "
                small
              >
                {{ data.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import analyticsService from "../service/analyticsService";
export default {
  name: "Home_View",
  data: () => ({
    id: "",
    template: "",
    report: [],
    headers: [
      {
        text: "Phone",
        sortable: false,
        value: "phone",
        align: "center",
      },
      {
        text: "date",
        value: "createdAt",
        align: "center",
      },
      {
        text: "Template",
        value: "convo.template",
        align: "center",
      },
      {
        text: "Blast ID",
        value: "convo.cohortId",
        align: "center",
      },
      {
        text: "Message Status",
        value: "hook",
        align: "start",
      },
    ],
  }),
  components: {},
  mounted() {
    this.getReport();
  },
  computed: {},
  methods: {
    getReport() {
      let that = this;
      that.id = that.$route.params.id;
      that.template = that.$route.params.template;
      analyticsService
        .GetAnalytics(that.id, that.template)
        .then(function (response) {
          console.log("Response", response);
          that.report = response.data;
        })
        .catch(function (error) {
          console.log("Error", error);
        });
    },
    getCount(data) {
      var sent = 0;
      var delivered = 0;
      var read = 0;
      var failed = 0;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].hook.length; j++) {
          if (data[i].hook[j].status === "sent") {
            sent++;
          } else if (data[i].hook[j].status === "delivered") {
            delivered++;
          } else if (data[i].hook[j].status === "read") {
            read++;
          } else if (data[i].hook[j].status === "failed") {
            failed++;
          }
        }
      }
      return {
        sent,
        delivered,
        read,
        failed,
      };
    },
  },
};
</script>

<template>
  <v-app>
    <v-main class="background">
      <v-container fluid>
        <v-container fluid>
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="8">
                <v-card
                  class="elevation-0"
                  outlined
                  style="border-radius: 10px"
                >
                  <v-stepper v-model="step" vertical class="elevation-0">
                    <v-stepper-step :complete="step > 1" step="1">
                      Understand the Template
                    </v-stepper-step>
                    <v-stepper-content step="1">
                      <v-card
                        class="mb-6 background"
                        outlined
                        style="border-radius: 10px"
                      >
                        <v-card-title>{{ templateData.name }} </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="6">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged.
                            </v-col>
                            <v-col cols="6">
                              <ol>
                                <li>
                                  Total Required Paramters:
                                  {{ bodyParameterCount }}
                                </li>
                                <li>All Parameters are Mandatory</li>
                                <li>All Parameters are String Type</li>
                                <li>
                                  Kindly refer the Template Preview shown on
                                  right side to provide the appropriate values
                                  for parameters
                                </li>
                                <li>
                                  In case of any doubts and problems, kindly
                                  reach out to
                                  {{ $store.state.site.supportEmail }} for help.
                                </li>
                              </ol>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>

                      <v-btn color="primary" @click="step = 2">
                        Continue
                      </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="step > 2" step="2">
                      Setup Blast
                    </v-stepper-step>
                    <v-stepper-content step="2">
                      <v-card
                        class="mb-5 background"
                        outlined
                        style="border-radius: 10px"
                      >
                        <v-card-text>
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row justify="start" class="mt-3">
                              <v-col cols="4" class="py-0">
                                <v-text-field
                                  label="Blast ID"
                                  outlined
                                  disabled
                                  v-model="cohort"
                                  style="border-radius: 10px"
                                  color="primary"
                                  required
                                  :rules="mandatoryRules"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="4" class="py-0">
                                <v-text-field
                                  label="Blast Start Date (YYYY-MM-DD)"
                                  outlined
                                  disabled
                                  v-model="blastStartDate"
                                  style="border-radius: 10px"
                                  color="primary"
                                  required
                                  :rules="mandatoryRules"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="4" class="py-0">
                                <v-text-field
                                  label="Blast End Date (YYYY-MM-DD)"
                                  outlined
                                  v-model="blastEndDate"
                                  disabled
                                  style="border-radius: 10px"
                                  color="primary"
                                  required
                                  :rules="mandatoryRules"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                      <v-btn color="primary" @click="step = 3">
                        Continue
                      </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="step > 3" step="3">
                      Fill Parameters
                    </v-stepper-step>
                    <v-stepper-content step="3">
                      <v-card
                        class="mb-5 background"
                        outlined
                        style="border-radius: 10px"
                      >
                        <v-card-text>
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row justify="start" class="mt-3">
                              <v-col
                                cols="4"
                                class="py-0"
                                v-for="i in bodyParameterCount"
                                :key="i"
                              >
                                <v-text-field
                                  :label="
                                    `Value for Body Parameter {{` + i + `}}`
                                  "
                                  outlined
                                  v-model="bodyData[i - 1]"
                                  style="border-radius: 10px"
                                  color="primary"
                                  required
                                  :rules="mandatoryRules"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="6" class="mt-n3">
                                <v-text-field
                                  outlined
                                  style="border-radius: 10px"
                                  v-if="
                                    headerComponent.type === 'HEADER' &&
                                    headerComponent.format != 'TEXT'
                                  "
                                  :label="
                                    `URL For ` +
                                    templateComponents[0].format.toLowerCase() +
                                    ` Parameter in Header`
                                  "
                                  v-model="headerData[0]"
                                  required
                                  :rules="mandatoryRules"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col
                                cols="6"
                                class="mt-n3"
                                v-if="interactionComponent.type === 'BUTTONS'"
                              >
                                <v-text-field
                                  v-for="(
                                    footer, index
                                  ) in interactionComponent.buttons"
                                  :key="index"
                                  outlined
                                  style="border-radius: 10px"
                                  :label="
                                    `Value for Parameter in ` + footer.url
                                  "
                                  v-model="interationData[index]"
                                  required
                                  :rules="mandatoryRules"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                      <v-btn color="primary" @click="validateParameters()">
                        Continue
                      </v-btn>

                      <v-btn text> Cancel </v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="step > 4" step="4">
                      Upload Users
                    </v-stepper-step>
                    <v-stepper-content step="4">
                      <v-card
                        color="background"
                        class="mb-6 elevation-0"
                        height="200px"
                        style="border-radius: 10px"
                      >
                        <v-card-text
                          >Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </v-card-text>
                        <v-card-text>
                          <usecsv-button
                            importerKey="e37caa59-28f2-42d0-abd0-b9544a175085"
                            v-slot="slotProps"
                          >
                            <v-btn
                              @click="slotProps.openModal"
                              class="elevation-0"
                              color="primary"
                              style="border-radius: 10px"
                            >
                              <v-icon class="mr-2" color="white">
                                mdi-file-import</v-icon
                              >
                              Import the Bulk Users
                            </v-btn>
                          </usecsv-button>
                        </v-card-text></v-card
                      >
                      <v-btn color="primary" @click="preview()">
                        Continue
                      </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </v-stepper-content>

                    <v-stepper-step step="5">
                      Preview and Trigger
                    </v-stepper-step>
                    <v-stepper-content step="5">
                      <v-card
                        color="background"
                        class="mb-6 elevation-0"
                        style="border-radius: 10px"
                      >
                        <v-card-text fluid>
                          {{ headerData }}
                        </v-card-text>
                        <v-card-text fluid>
                          {{ bodyData }}
                        </v-card-text>
                        <v-card-text fluid>
                          {{ footerData }}
                        </v-card-text>
                        <v-card-text fluid>
                          {{ interationData }}
                        </v-card-text>
                        <v-card-text fluid>
                          {{ cohort }} {{ blastStartDate }}
                          {{ blastEndDate }}
                        </v-card-text>
                        <v-card-text>
                          {{ cohortUsers }}
                        </v-card-text>
                        <v-btn @click="blastit">Blast IT</v-btn>
                      </v-card>
                      <v-btn color="primary" @click="step = 1">
                        Continue
                      </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </v-stepper-content>
                  </v-stepper>
                </v-card></v-col
              >
              <v-col cols="12" xl="3" lg="3">
                <v-card
                  class="mx-auto elevation-0"
                  outlined
                  style="border-radius: 10px"
                >
                  <span
                    fluid
                    v-for="(data, index) in templateComponents"
                    :key="index"
                  >
                    <!-- if Header has a image block starts -->
                    <v-img
                      v-if="data.type == 'HEADER' && data.format == 'IMAGE'"
                      height="200"
                      style="border-radius: 10px"
                      cover
                      :src="data.example.header_handle[0]"
                    ></v-img>
                    <!-- if Header has a image block ends -->

                    <!-- body text render block starts -->
                    <v-card-text
                      v-if="data.type == 'BODY'"
                      v-html="formatted_String"
                    >
                    </v-card-text>
                    <!-- body text render block ends -->

                    <!-- footer text render block starts -->
                    <v-card-subtitle
                      class="mt-n5"
                      v-if="data.type == 'FOOTER'"
                      style="font-weight: light"
                    >
                      {{ data.text }}
                    </v-card-subtitle>
                    <!-- body text render block ends -->

                    <!-- Interaction buttons block starts -->
                    <v-divider
                      v-if="data.type == 'BUTTONS'"
                      class="mt-n2"
                    ></v-divider>
                    <v-card-actions v-if="data.type == 'BUTTONS'">
                      <v-btn
                        text
                        block
                        class="elevation-0 text--primary"
                        style="text-transform: capitalize"
                        v-for="(btn, index) in data.buttons"
                        :key="index"
                      >
                        <v-icon
                          v-if="btn.type == 'URL'"
                          small
                          class="mr-2"
                          color="primary"
                          >mdi-open-in-new</v-icon
                        >
                        {{ btn.text }}</v-btn
                      >
                    </v-card-actions>
                    <!-- Interaction buttons block ends -->
                  </span>
                </v-card>
                <v-row>
                  <v-col cols="4">
                    <v-btn
                      :disabled="step != 5"
                      color="primary"
                      rounded
                      block
                      class="elevation-0 mt-4"
                      @click="IngestPreview()"
                    >
                      Generate Template Preview
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import templateService from "../service/templateService";
import cohortService from "../service/cohortService";
import moment from "moment";
export default {
  name: "TemplateView",
  components: {},
  data: () => ({
    templateData: [],
    templateComponents: [],
    headerComponent: [],
    bodyComponent: [],
    footerComponent: [],
    interactionComponent: [],
    cohortUsers: [],
    bodyParameterCount: 0,
    id: "",
    valid: true,
    bodyData: [],
    headerData: [],
    footerData: [],
    interationData: [],
    formatted_String: "",
    cohort: "",
    blastStartDate: "",
    blastEndDate: "",
    mandatoryRules: [(v) => !!v || "This field is required"],

    step: 1,
  }),
  mounted() {
    this.fetchTemplateDetails();
    this.blastIdGen();
  },
  computed: {},
  methods: {
    fetchTemplateDetails() {
      let that = this;
      that.id = that.$route.params.id;

      templateService
        .GetTemplateDetails(that.id)
        .then((response) => {
          that.templateData = response.data[0];
          that.templateComponents = that.templateData["meta"]["components"];
          that.headerComponent = that.templateComponents[0];
          that.bodyComponent = that.templateComponents[1];
          that.footerComponent = that.templateComponents[2];
          that.interactionComponent = that.templateComponents[3];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          that.CalculateParameters();
          that.GeneratePreview(this.bodyComponent["text"]);
        });
    },
    CalculateParameters() {
      let that = this;
      var body = that.templateComponents[1]["text"];
      that.bodyParameterCount = body.match(/{{[^}]*}}/g).length;
      return that.bodyParameterCount;
    },
    GeneratePreview(text) {
      text = text.replace(/\*([^*]+)\*/g, "<b>$1</b>");
      this.formatted_String = text.replace(/\n/g, "</br>");
      return this.formatted_String;
    },
    IngestPreview() {
      let len = this.formatted_String.match(/{{[^}]*}}/g).length;
      for (let i = 0; i < len; i++) {
        this.formatted_String = this.formatted_String.replace(
          /{{[^}]*}}/,
          this.bodyData[i]
        );
      }
      this.templateComponents[0].example.header_handle[0] = this.headerData[0];
      this.GeneratePreview(this.formatted_String);
    },
    validateParameters() {
      console.log("hit");
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.step = 4;
      }
    },
    blastIdGen() {
      let uniqueId,
        randomId,
        uniqueDateNumber,
        uniqueTimeNumber,
        dateTime = new Date().toLocaleString();
      uniqueDateNumber =
        parseInt(dateTime.split(",")[0].split("/")[0]) +
        parseInt(dateTime.split(",")[0].split("/")[1]) +
        parseInt(dateTime.split(",")[0].split("/")[2]); //date number
      uniqueTimeNumber =
        parseInt(dateTime.split(",")[1].split(" ")[1].split(":")[0]) +
        parseInt(dateTime.split(",")[1].split(" ")[1].split(":")[1]) +
        parseInt(dateTime.split(",")[1].split(" ")[1].split(":")[2]); //time number
      randomId = Math.floor(Math.random() * (999999 - 111111 + 1) + 111111); //Math.floor(Math.random()*(max-min+1)+min)
      uniqueId = `${randomId + uniqueDateNumber + uniqueTimeNumber}`;
      this.cohort = "WBA_C_" + uniqueId;
      this.blastStartDate = moment().format("YYYY-MM-DD");
      this.blastEndDate = moment().format("YYYY-MM-DD");
      return this.cohort;
    },
    getCohortUsers() {
      let that = this;
      cohortService
        .getCohorts(that.cohort)
        .then((resp) => {
          that.cohortUsers = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    preview() {
      let that = this;
      this.getCohortUsers();
      that.step = 5;
    },
    blastit() {
      var payload = {
        bodyData: this.bodyData,
        headerData: this.headerData,
        footerData: this.footerData,
        interationData: this.interationData,
        templateId: this.id,
        cohortId: this.cohort,
      };
      console.log(payload);
    },
  },
};
</script>

<template>
  <v-app>
    <v-main class="background">
      <v-container fluid class="mt-5">
        <v-card class="elevation-0 rounded-t-xl rounded-b-xl">
          <v-container> </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "Home_View",
  data: () => ({}),
  components: {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>

const axios = require("axios");

var analytics = {
  getCohorts: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_WBA_API_Host + "/api/cohort/ListCohorts")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },
  GetReports: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_WBA_API_Host + "/api/cohort/details")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  GetAnalytics: async (id, template) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_WBA_API_Host +
            "/api/cohort/details?id=" +
            id +
            "&template=" +
            template
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },
};

export default analytics;

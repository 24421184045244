const axios = require("axios");

var cohort = {
  getCohorts: async (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_WBA_API_Host +
            "/api/cohort/ListUsers?cohortId=" +
            payload
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },
};

export default cohort;

const axios = require("axios");

var Templates = {
  GetTemplates: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_WBA_API_Host + "/api/template/list")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },
  GetTemplateDetails: async (templateID) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_WBA_API_Host +
            "/api/template/list?templateId=" +
            templateID
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },
};

export default Templates;

const locals = [
  {
    code: "C",
    name: "C , POSIX",
  },
  {
    code: "POSIX",
    name: "C, POSIX",
  },
  {
    code: "af_ZA",
    name: "Afrikaans, South Africa",
  },
  {
    code: "ar_AE",
    name: "Arabic, United Arab Emirates",
  },
  {
    code: "ar_BH",
    name: "Arabic, Bahrain",
  },
  {
    code: "ar_DZ",
    name: "Arabic, Algeria",
  },
  {
    code: "ar_EG",
    name: "Arabic, Egypt",
  },
  {
    code: "ar_IQ",
    name: "Arabic, Iraq",
  },
  {
    code: "ar_JO",
    name: "Arabic, Jordan",
  },
  {
    code: "ar_KW",
    name: "Arabic, Kuwait",
  },
  {
    code: "ar_LY",
    name: "Arabic, Libya",
  },
  {
    code: "ar_MA",
    name: "Arabic, Morocco",
  },
  {
    code: "ar_OM",
    name: "Arabic, Oman",
  },
  {
    code: "ar_QA",
    name: "Arabic, Qatar",
  },
  {
    code: "ar_SA",
    name: "Arabic, Saudi Arabia",
  },
  {
    code: "ar_TN",
    name: "Arabic, Tunisia",
  },
  {
    code: "ar_YE",
    name: "Arabic, Yemen",
  },
  {
    code: "as_IN",
    name: "Assamese, India",
  },
  {
    code: "az_AZ",
    name: "Azerbaijani, Azerbaijan",
  },
  {
    code: "be_BY",
    name: "Belarusian, Belarus",
  },
  {
    code: "bg_BG",
    name: "Bulgarian, Bulgaria",
  },
  {
    code: "bn_IN",
    name: "Bengali, India",
  },
  {
    code: "bs_BA",
    name: "Bosnian, Bosnia and Herzegovina",
  },
  {
    code: "ca_ES",
    name: "Catalan, Spain",
  },
  {
    code: "cs_CZ",
    name: "Czech, Czech Republic",
  },
  {
    code: "da_DK",
    name: "Danish, Denmark",
  },
  {
    code: "de_AT",
    name: "German, Austria",
  },
  {
    code: "de_BE",
    name: "German, Belgium",
  },
  {
    code: "de_CH",
    name: "German, Switzerland",
  },
  {
    code: "de_DE",
    name: "German, Germany",
  },
  {
    code: "de_LI",
    name: "German, Liechtenstein",
  },
  {
    code: "de_LU",
    name: "German, Luxembourg",
  },
  {
    code: "el_CY",
    name: "Greek, Cyprus",
  },
  {
    code: "el_GR",
    name: "Greek, Greece",
  },
  {
    code: "en_AU",
    name: "English, Australia",
  },
  {
    code: "en_BW",
    name: "English, Botswana",
  },
  {
    code: "en_CA",
    name: "English, Canada",
  },
  {
    code: "en_GB",
    name: "English, United Kingdom",
  },
  {
    code: "en_HK",
    name: "English, Hong Kong SAR China",
  },
  {
    code: "en_IE",
    name: "English, Ireland",
  },
  {
    code: "en_IN",
    name: "English, India",
  },
  {
    code: "en",
    name: "English",
  },
  {
    code: "id",
    name: "Indonesian",
  },
  {
    code: "es",
    name: "Spanish",
  },
  {
    code: "en_MT",
    name: "English, Malta",
  },
  {
    code: "en_NZ",
    name: "English, New Zealand",
  },
  {
    code: "en_PH",
    name: "English, Philippines",
  },
  {
    code: "en_SG",
    name: "English, Singapore",
  },
  {
    code: "en_US",
    name: "English, U.S.A.",
  },
  {
    code: "en_ZW",
    name: "English, Zimbabwe",
  },
  {
    code: "es_AR",
    name: "Spanish, Argentina",
  },
  {
    code: "es_BO",
    name: "Spanish, Bolivia",
  },
  {
    code: "es_CL",
    name: "Spanish, Chile",
  },
  {
    code: "es_CO",
    name: "Spanish, Colombia",
  },
  {
    code: "es_CR",
    name: "Spanish, Costa Rica",
  },
  {
    code: "es_DO",
    name: "Spanish, Dominican Republic",
  },
  {
    code: "es_EC",
    name: "Spanish, Ecuador",
  },
  {
    code: "es_ES",
    name: "Spanish, Spain",
  },
  {
    code: "es_GT",
    name: "Spanish, Guatemala",
  },
  {
    code: "es_HN",
    name: "Spanish, Honduras",
  },
  {
    code: "es_MX",
    name: "Spanish, Mexico",
  },
  {
    code: "es_NI",
    name: "Spanish, Nicaragua",
  },
  {
    code: "es_PA",
    name: "Spanish, Panama",
  },
  {
    code: "es_PE",
    name: "Spanish, Peru",
  },
  {
    code: "es_PR",
    name: "Spanish, Puerto Rico",
  },
  {
    code: "es_PY",
    name: "Spanish, Paraguay",
  },
  {
    code: "es_SV",
    name: "Spanish, El Salvador",
  },
  {
    code: "es_US",
    name: "Spanish, U.S.A.",
  },
  {
    code: "es_UY",
    name: "Spanish, Uruguay",
  },
  {
    code: "es_VE",
    name: "Spanish, Venezuela",
  },
  {
    code: "et_EE",
    name: "Estonian, Estonia",
  },
  {
    code: "fi_FI",
    name: "Finnish, Finland",
  },
  {
    code: "fr_BE",
    name: "French, Belgium",
  },
  {
    code: "fr_CA",
    name: "French, Canada",
  },
  {
    code: "fr_CH",
    name: "French, Switzerland",
  },
  {
    code: "fr_FR",
    name: "French, France",
  },
  {
    code: "fr_LU",
    name: "French, Luxembourg",
  },
  {
    code: "gu_IN",
    name: "Gujarati, India",
  },
  {
    code: "he_IL",
    name: "Hebrew, Israel",
  },
  {
    code: "hi_IN",
    name: "Hindi, India",
  },
  {
    code: "hr_HR",
    name: "Croatian, Croatia",
  },
  {
    code: "hu_HU",
    name: "Hungarian, Hungary",
  },
  {
    code: "hy_AM",
    name: "Armenian, Armenia",
  },
  {
    code: "id_ID",
    name: "Indonesian, Indonesia",
  },
  {
    code: "is_IS",
    name: "Icelandic, Iceland",
  },
  {
    code: "it_CH",
    name: "Italian, Switzerland",
  },
  {
    code: "it_IT",
    name: "Italian, Italy",
  },
  {
    code: "ja_JP",
    name: "Japanese, Japan",
  },
  {
    code: "ka_GE",
    name: "Georgian, Georgia",
  },
  {
    code: "kk_KZ",
    name: "Kazakh, Kazakhstan",
  },
  {
    code: "kn_IN",
    name: "Kannada, India",
  },
  {
    code: "ko_KR",
    name: "Korean, Korea",
  },
  {
    code: "ks_IN",
    name: "Kashmiri, India",
  },
  {
    code: "ku_TR",
    name: "Kurdish, Turkey",
  },
  {
    code: "ku_TR",
    name: "Kurdish (Sorani), Turkey",
  },
  {
    code: "ky_KG",
    name: "Kirghiz, Kyrgyzstan",
  },
  {
    code: "lt_LT",
    name: "Lithuanian, Lithuania",
  },
  {
    code: "lv_LV",
    name: "Latvian, Latvia",
  },
  {
    code: "mk_MK",
    name: "Macedonian, Macedonia",
  },
  {
    code: "ml_IN",
    name: "Malayalam, India",
  },
  {
    code: "mr_IN",
    name: "Marathi, India",
  },
  {
    code: "ms_MY",
    name: "Malay, Malaysia",
  },
  {
    code: "mt_MT",
    name: "Maltese, Malta",
  },
  {
    code: "nb_NO",
    name: "Bokmal, Norway",
  },
  {
    code: "nl_BE",
    name: "Dutch, Belgium",
  },
  {
    code: "nl_NL",
    name: "Dutch, Netherlands",
  },
  {
    code: "nn_NO",
    name: "Nynorsk, Norway",
  },
  {
    code: "or_IN",
    name: "Oriya, India",
  },
  {
    code: "pa_IN",
    name: "Punjabi, India",
  },
  {
    code: "pl_PL",
    name: "Polish, Poland",
  },
  {
    code: "pt_BR",
    name: "Portuguese, Brazil",
  },
  {
    code: "pt_PT",
    name: "Portuguese, Portugal",
  },
  {
    code: "ro_RO",
    name: "Romanian, Romania",
  },
  {
    code: "ru_RU",
    name: "Russian, Russia",
  },
  {
    code: "ru_UA",
    name: "Russian, Ukraine",
  },
  {
    code: "sa_IN",
    name: "Sanskrit, India",
  },
  {
    code: "sk_SK",
    name: "Slovak, Slovakia",
  },
  {
    code: "sl_SI",
    name: "Slovenian, Slovenia",
  },
  {
    code: "sq_AL",
    name: "Albanian, Albania",
  },
  {
    code: "sr_ME",
    name: "Serbian, Montenegro",
  },
  {
    code: "sr_ME",
    name: "Serbian, Montenegro (Latin)",
  },
  {
    code: "sr_RS",
    name: "Serbian, Serbia",
  },
  {
    code: "sr_RS",
    name: "Serbian, Serbia (Latin)",
  },
  {
    code: "sv_SE",
    name: "Swedish, Sweden",
  },
  {
    code: "ta_IN",
    name: "Tamil, India",
  },
  {
    code: "te_IN",
    name: "Telugu, India",
  },
  {
    code: "th_TH",
    name: "Thai, Thailand",
  },
  {
    code: "tr_TR",
    name: "Turkish, Turkey",
  },
  {
    code: "uk_UA",
    name: "Ukrainian, Ukraine",
  },
  {
    code: "vi_VN",
    name: "Vietnamese, Vietnam",
  },
  {
    code: "zh_CN",
    name: "Simplified Chinese, China",
  },
  {
    code: "zh_HK",
    name: "Traditional Chinese, Hong Kong SAR China",
  },
  {
    code: "zh_SG",
    name: "Chinese, Singapore",
  },
  {
    code: "zh_TW",
    name: "Traditional Chinese, Taiwan",
  },
];

const data = {};
data.locals = locals;
export default data;
